.App {
  text-align: center;
  
}

.title{

  background-color: brown;
  
}

.list{
  background-color: brown;
  border-radius: 6px;
  margin-bottom: 20px;
  padding: 10px 20px; /* Adjust padding as needed */
  text-align: left;
  font-size: 22px;
  position: relative;
  color: aliceblue;
  max-width: 70%;
  margin-left: 10%;
  overflow: hidden;
  
}

.pizza{
  align-items: center;
  border: solid;
  border-radius: 50px;
  width: 50%;
  margin-left: 25%;
  margin-top: 50px;
  background-color: brown;
}

.delete{
  padding-left: 90%;
}

/* RestaurantPizzaForm.css */

.form-label {
  display: block;
  margin-bottom: 10px;
}

.form-select,
.form-input {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-button {
  background-color: brown;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.form-button:hover {
  background-color: #899489;
}
